.main {
  /*  height: 100vh; */
  background-color: #f0ebf8;
  color: #666362;

  /* display: flex;
  align-items: center;
  flex-direction: column; */
}
.header-title {
  background-color: #734ae8;
  background-image: linear-gradient(315deg, #734ae8 0%, #89d4cf 74%);
}
